import React, { useState } from 'react';
import {Button} from 'primereact/button';
import { useProductStore, useRootStore } from '../hooks/use-stores';
import { Link } from 'react-router-dom';
import { getAbsoluteURL } from '../common/common';
import { getLocalizedFilterClass } from '../common/search-criteria';

const BannerHome = () => {

    const productStore = useProductStore();
    const rootStore = useRootStore();
    const lang = productStore.rootStore.appLang;
    const getCurrLangLocalizedFilterClass = (key) => ( getLocalizedFilterClass(key, rootStore.appLang) );
    
    return (

      <div className="banner-champagne">
                {lang=="en" && <React.Fragment>
                    <h2>Who loves to toast <br/>toasts all year round</h2>
                    <Link className='banner-button' to={getAbsoluteURL(`/products/class/${getCurrLangLocalizedFilterClass('champagne')}`, rootStore.appLang)}>
                        Discover our Champagnes
                    </Link>
                </React.Fragment> }
                {lang!="en" && <React.Fragment>
                    <h2>Chi ama brindare <br/>brinda tutto l'anno</h2>
                        <Link className='banner-button' to={getAbsoluteURL(`/products/class/${getCurrLangLocalizedFilterClass('champagne')}`, rootStore.appLang)}>
                            Scopri i nostri Champagne
                        </Link>
                </React.Fragment> }
            </div>
            
       
    );
}
export default BannerHome;
